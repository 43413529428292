import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Guid: any;
  JSON: any;
};

export class AuthInput {
  password: Scalars['String'];
  username: Scalars['String'];
};

export class DefaultMessage {
  code: Scalars['Int'];
  message: Scalars['String'];
};

export class Mutation {
  createReserve?: Maybe<DefaultMessage>;
  createUser?: Maybe<DefaultMessage>;
  forgotPassword?: Maybe<DefaultMessage>;
  login?: Maybe<UserVm>;
  resetPassword?: Maybe<DefaultMessage>;
  updateReserve?: Maybe<Reserve>;
};


export class MutationCreateReserveArgs {
  data?: InputMaybe<ReserveCreate>;
};


export class MutationCreateUserArgs {
  data?: InputMaybe<UserCreateInput>;
};


export class MutationForgotPasswordArgs {
  email?: InputMaybe<Scalars['String']>;
};


export class MutationLoginArgs {
  data?: InputMaybe<AuthInput>;
};


export class MutationResetPasswordArgs {
  code?: InputMaybe<Scalars['String']>;
  new_password?: InputMaybe<Scalars['String']>;
};


export class MutationUpdateReserveArgs {
  data?: InputMaybe<ReserveUpdate>;
};

export class Property {
  address: PropertyAddress;
  advertiser_code: Scalars['String'];
  announced_at: Scalars['String'];
  code: Scalars['String'];
  condominium?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  iptu?: Maybe<Scalars['String']>;
  property_type: Scalars['String'];
  rent_price?: Maybe<Scalars['String']>;
  sell_price?: Maybe<Scalars['String']>;
  specification?: Maybe<PropertySpecifications>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export class PropertyAddress {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  neighborhood: Scalars['String'];
  postal_code: Scalars['String'];
  state: Scalars['String'];
};

export class PropertyInput {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  keyword?: InputMaybe<Scalars['String']>;
  localization?: InputMaybe<Scalars['Boolean']>;
  property_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search_type?: InputMaybe<Scalars['String']>;
  specifications?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export class PropertyLink {
  id: Scalars['ID'];
  option?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['String']>;
  property_link?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export class PropertySpecifications {
  details?: Maybe<Array<Maybe<PropertySpecificationsDetails>>>;
};

export class PropertySpecificationsDetails {
  type: Scalars['String'];
  value: Scalars['String'];
};

export class Query {
  currentUser?: Maybe<User>;
  dropDownCategories?: Maybe<Array<Maybe<SearchKindDropDown>>>;
  dropDownProperties?: Maybe<Array<Maybe<SearchPropertyDropDown>>>;
  dropDownPropertyTypes?: Maybe<Array<Maybe<SearchPropertyTypeDropDown>>>;
  dropDownType?: Maybe<Array<Maybe<SearchTypeDropDown>>>;
  propertyById?: Maybe<Property>;
  reserveById?: Maybe<Reserve>;
  scrapingPropertyDetail?: Maybe<Scalars['String']>;
  scrapingPropertyLink?: Maybe<Array<Maybe<PropertyLink>>>;
  searchProperties?: Maybe<Array<Maybe<Property>>>;
  searchReserve?: Maybe<Array<Maybe<Reserve>>>;
  users?: Maybe<Array<Maybe<User>>>;
};


export class QueryPropertyByIdArgs {
  property_id: Scalars['Guid'];
};


export class QueryReserveByIdArgs {
  reserve_id: Scalars['Guid'];
};


export class QuerySearchPropertiesArgs {
  data?: InputMaybe<PropertyInput>;
};


export class QuerySearchReserveArgs {
  data?: InputMaybe<ReserveInput>;
};

export class Reserve {
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  property_id: Scalars['Guid'];
  property_link: Scalars['String'];
  receive_similar: Scalars['Boolean'];
  status: Scalars['Boolean'];
  text: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export class ReserveCreate {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  property_id: Scalars['Guid'];
  property_link: Scalars['String'];
  receive_similar: Scalars['Boolean'];
};

export class ReserveInput {
  email?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export class ReserveUpdate {
  id: Scalars['Guid'];
  status: Scalars['Boolean'];
  text?: InputMaybe<Scalars['String']>;
};

export class SearchKindDropDown {
  kind?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export class SearchPropertyDropDown {
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export class SearchPropertyTypeDropDown {
  text?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<SearchTypeDropDown>>>;
  value?: Maybe<Scalars['String']>;
};

export class SearchTypeDropDown {
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export class User {
  email: Scalars['String'];
  id: Scalars['Guid'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  password: Scalars['String'];
  reset_password_code: Scalars['String'];
  reset_password_expiration?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export class UserCreateInput {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export class UserVm {
  token: Scalars['String'];
  username: Scalars['String'];
};

export type UserFragment = { id: any, email: string, name: string, username: string, is_active: boolean, password: string, reset_password_code: string, reset_password_expiration?: any | null };

export type DefaultMessageFragment = { message: string, code: number };

export type UserVmFragment = { token: string, username: string };

export type ReserveFragment = { id: string, email: string, name: string, text: string, status: boolean, updated_at: any, receive_similar: boolean, property_link: string, property_id: any, phone: string, deleted_at?: any | null, created_at: any };

export type SearchAllReservesQueryVariables = Exact<{
  input: ReserveInput;
}>;


export type SearchAllReservesQuery = { searchReserve?: Array<{ id: string, email: string, name: string, text: string, status: boolean, updated_at: any, receive_similar: boolean, property_link: string, property_id: any, phone: string, deleted_at?: any | null, created_at: any } | null> | null };

export type UpdateReserveMutationVariables = Exact<{
  input?: InputMaybe<ReserveUpdate>;
}>;


export type UpdateReserveMutation = { updateReserve?: { id: string, email: string, name: string, text: string, status: boolean, updated_at: any, receive_similar: boolean, property_link: string, property_id: any, phone: string, deleted_at?: any | null, created_at: any } | null };

export type LoginMutationVariables = Exact<{
  data: AuthInput;
}>;


export type LoginMutation = { login?: { token: string, username: string } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser?: { id: any, email: string, name: string, username: string, is_active: boolean, password: string, reset_password_code: string, reset_password_expiration?: any | null } | null };

export type CreateUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;


export type CreateUserMutation = { createUser?: { message: string, code: number } | null };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { users?: Array<{ id: any, email: string, name: string, username: string, is_active: boolean, password: string, reset_password_code: string, reset_password_expiration?: any | null } | null> | null };

export type ForgotPasswordMutationVariables = Exact<{
  input?: InputMaybe<Scalars['String']>;
}>;


export type ForgotPasswordMutation = { forgotPassword?: { message: string, code: number } | null };

export type ResetPasswordMutationVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
}>;


export type ResetPasswordMutation = { resetPassword?: { message: string, code: number } | null };

export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  name
  username
  is_active
  password
  reset_password_code
  reset_password_expiration
}
    `;
export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  code
}
    `;
export const UserVmFragmentDoc = gql`
    fragment UserVM on UserVM {
  token
  username
}
    `;
export const ReserveFragmentDoc = gql`
    fragment Reserve on Reserve {
  id
  email
  name
  text
  status
  updated_at
  receive_similar
  property_link
  property_id
  phone
  deleted_at
  created_at
}
    `;
export const SearchAllReservesDocument = gql`
    query SearchAllReserves($input: ReserveInput!) {
  searchReserve(data: $input) {
    ...Reserve
  }
}
    ${ReserveFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchAllReservesGQL extends Apollo.Query<SearchAllReservesQuery, SearchAllReservesQueryVariables> {
    document = SearchAllReservesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateReserveDocument = gql`
    mutation UpdateReserve($input: ReserveUpdate) {
  updateReserve(data: $input) {
    ...Reserve
  }
}
    ${ReserveFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateReserveGQL extends Apollo.Mutation<UpdateReserveMutation, UpdateReserveMutationVariables> {
    document = UpdateReserveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($data: AuthInput!) {
  login(data: $data) {
    ...UserVM
  }
}
    ${UserVmFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation createUser($input: UserCreateInput!) {
  createUser(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users {
  users {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: String) {
  forgotPassword(email: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($code: String, $newPassword: String) {
  resetPassword(code: $code, new_password: $newPassword) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }